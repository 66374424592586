<template>
  <base-section
    id="features"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'fa-head-side-mask',
          title: 'COVID-19 Safe',
          text: 'Without the requirement to handle patients’ credit and debit cards, the risk of spreading COVID-19 is greatly reduced.',
        },
        {
          icon: 'fa-clock',
          title: '24/7 Uptime',
          text: 'Your payment portal will be up and running 24 hours per day, allowing you pateints to make payments when it is convenient for them.',
        },
        {
          icon: 'fa-money-bill-wave',
          title: 'Next Day Funding',
          text: 'When transactions are batched before 8pm EST, you will receive your money the next business day.',
        },
        {
          icon: 'fa-dollar-sign',
          title: 'Competitive Pricing',
          text: 'The monthly charge to use the Patient Payment Portal® is only $9.95. Processing charges are equivalent to Square®.',
        },
      ],
    }),
  }
</script>
